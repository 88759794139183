<template>
  <div class="content" id="pjax-container">
    <div class="row">
      <div class="col-12">
        <div class="block block-rounded">
          <div class="block-header block-header-default">
            <h3 class="block-title">全部账号列表 - 智慧校园</h3>
          </div>
        </div>
      </div>

      <div v-for="account in list" :key="account.id" class="col-md-6 col-xl-3">
        <div class="block block-rounded text-center">
          <div class="block-content block-content-full bg-pulse-lighter">
            <img
              class="img-avatar img-avatar-thumb"
              v-bind:src="account.avatar"
              alt=""
            />
          </div>
          <div class="block-content">
            <div class="fw-semibold mb-1">{{ account.nickName }}</div>

            <div v-if="account.state === 1" class="fs-sm text-success mb-1">
              正常运行中
            </div>

            <div v-else class="fs-sm text-danger mb-1">状态已失效</div>
            <div class="fs-sm text-muted">
              <i class="far fa-clock opacity-75"></i> {{ account.createTime }}
            </div>
          </div>
          <div class="block-content block-content-full">
            <div class="row g-sm">
              <div class="col-8">
                <router-link
                  class="btn btn-sm btn-alt-primary w-100"
                  :to="{ path: '/wisdom/Info', query: { uin: account.uin } }"
                  >管理账号
                </router-link>
              </div>
              <div class="col-4">
                <a
                  class="btn btn-sm btn-alt-danger w-100"
                  @click="del(account.uin)"
                >
                  <i class="si si-close"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="list === undefined || list.length <= 0" class="col-12">
        <div class="block block-rounded">
          <div class="block-content text-center">
            <i class="si si-drawer fa-2x"></i>
            <p class="text-muted fs-sm">暂无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  inject: ["reload"],

  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList().then((res) => {
      this.list = res.data;
    });
  },

  methods: {
    getList() {
      return this.$http.get("/wisdom/list");
    },

    del(uin) {
      this.$http
        .delete("/wisdom/delete", {
          params: {
            uin: uin,
          },
        })
        .then((res) => {
          if (res.code === "200") {
            this.$message.success(res.msg);

            this.reload();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style scoped></style>
